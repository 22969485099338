<template>
  <div class="service-setting">
    <PageTitleExtra
      :title="$t('attachServiceSetting.title')"
      :tooltip="$t('attachServiceSetting.tooltip')"
      cyBtn1="new-attach-service-btn"
      :btn="$t('common.button.create.text')"
      @btnClick="openDialog('create')"
    />

    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        testName="search_name"
        clearable
        :placeholder="$t('attachServiceSetting.search.service.placeholder')"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable v-loading="loading" :data="serviceList" testName="預約附加服務">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" :label="$t('attachServiceSetting.table.name.title')" width="150" align="center" />
        <BaseElTableColumn v-if="!isOverseasShop" prop="isPublic" :label="$t('attachServiceSetting.table.isPublic.title')" width="120" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPublic)">{{ (scope.row.isPublic)? $t('common.yes.text') : $t('common.no.text') }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="usePubApt" prop="isPubApt" :label="$t('serviceSetting.table.isPubApt.title')" width="120" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.isPubApt)">{{ (scope.row.isPubApt)? $t('common.yes.text') : $t('common.no.text') }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="price" :label="$t('attachServiceSetting.table.price.title')" align="center">
          <template slot-scope="scope">
            {{ displayCurrencySymbol }} {{ scope.row.price }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="(chargeType === 'checkout' && useFeatures.forceCanHidePrice) || chargeType !== 'checkout'" prop="showPrice" :label="$t('attachServiceSetting.table.showPrice.title')" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showPrice || scope.row.showPrice === '0')? '-' : scope.row.showPrice }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="bookingTime" :label="$t('attachServiceSetting.table.bookingTime.title')" align="center" />
        <BaseElTableColumn prop="showTime" :label="$t('attachServiceSetting.table.showTime.title')" align="center">
          <template slot-scope="scope">
            {{ (! scope.row.showTime || scope.row.showTime === 0)? '-' : scope.row.showTime }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" :label="$t('attachServiceSetting.table.order.title')" align="center" />
        <BaseElTableColumn prop="name" :label="$t('common.table.action.text')" fixed="right" width="150" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :hideCopy="false"
              :testName="scope.row?.name"
              @edit="openDialog('update'), syncFormData(scope.row), selectRow=scope.row, findAttachService()"
              @delete="deleteDialog = true, selectRow=scope.row"
              @copy="onCopyService(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="serviceCount"
        @pageChange="refresh(false)"
      />
    </section>

    <el-dialog :title="dialogTitle" :visible.sync="showDialog" :close-on-click-modal="false" @close="resetForm()">
      <BaseElForm ref="form" :model="formData" label-position="top" :rules="formRules">
        <BaseElFormItem :label="$t('attachServiceSetting.dialog.name.title')" prop="name">
          <BaseElInput
            v-model="formData.name"
            :maxlength="64"
            show-word-limit
            testName="formData_name"
            :placeholder="$t('attachServiceSetting.dialog.name.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="!isOverseasShop" :label="$t('attachServiceSetting.table.isPublic.title')">
          <BaseElSwitch
            v-model="formData.isPublic"
            testName="formData_isPublic"
            :active-text="$t('common.yes.text')"
            :inactive-text="$t('common.no.text')"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="usePubApt" :label="$t('createReservationService.isPubApt.title')">
          <BaseElSwitch
            v-model="formData.isPubApt"
            testName="formData_isPubApt"
            :active-text="$t('common.yes.text')"
            :inactive-text="$t('common.no.text')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('attachServiceSetting.dialog.bookingTime.title')" prop="bookingTime">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('attachServiceSetting.dialog.bookingTime.title')"
              class="title"
            >
              {{ $t('attachServiceSetting.dialog.bookingTime.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <div class="flex flex-col">
            <BaseElInput
              v-model="formData.bookingTime"
              testName="formData_bookingTime"
              :placeholder="$t('attachServiceSetting.dialog.bookingTime.placeholder')"
            />
            <BaseElCheckbox
              v-model="formData.hideTime"
              testName="formData_hideTime"
            >
              {{ $t('attachServiceSetting.dialog.hideTime.title')}}
            </BaseElCheckbox>
          </div>
        </BaseElFormItem>
        <BaseElFormItem v-if="formData.hideTime" :label="$t('attachServiceSetting.dialog.showTime.title')" prop="showTime">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('attachServiceSetting.dialog.showTime.title')"
              class="title"
            >
              {{ $t('attachServiceSetting.dialog.showTime.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElInput
            v-model="formData.showTime"
            testName="formData_showTime"
            :placeholder="$t('attachServiceSetting.dialog.showTime.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('attachServiceSetting.dialog.price.title')" prop="price">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('attachServiceSetting.dialog.price.title')"
              class="title"
            >
            <template v-if="isOverseasShop" v-slot:subTitle>
              <span class="text-gray-80 text-sm">{{ $t('branchSetting.table.currency.title') }}：{{ displayCurrency }}</span>
            </template>
              {{ $t('attachServiceSetting.dialog.price.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <div class="flex flex-col">
            <p v-if="chargeType === 'checkout' && !useFeatures.forceCanHidePrice" class="text-sm leading-[17.38px] mb-[8px]">
              {{ $t('attachServiceSetting.dialog.price.rule.title')}}
            </p>
            <BaseElInput
              v-model="formData.price"
              testName="formData_price"
              :placeholder="$t('attachServiceSetting.dialog.price.placeholder')"
            />
            <BaseElCheckbox
              v-model="formData.hidePrice"
              :disabled="chargeType === 'checkout' && !useFeatures.forceCanHidePrice"
              testName="formData_hidePrice"
            >
              {{ $t('attachServiceSetting.dialog.hidePrice.title')}}
            </BaseElCheckbox>
          </div>
        </BaseElFormItem>
        <BaseElFormItem v-if="formData.hidePrice" :label="$t('attachServiceSetting.dialog.showPrice.title')" prop="showPrice">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('attachServiceSetting.dialog.showPrice.title')"
              class="title"
            >
              {{ $t('attachServiceSetting.dialog.showPrice.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElInput
            v-model="formData.showPrice"
            :maxlength="40"
            show-word-limit
            testName="formData_showPrice"
            :placeholder="$t('attachServiceSetting.dialog.showPrice.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('attachServiceSetting.dialog.order.title')" prop="order">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('attachServiceSetting.dialog.order.title')"
              class="title"
            >
            {{ $t('attachServiceSetting.dialog.order.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElInput
            v-model="formData.order"
            testName="formData_order"
            :placeholder="$t('attachServiceSetting.dialog.order.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('attachServiceSetting.dialog.services.title')" prop="services">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('attachServiceSetting.dialog.services.title')"
              class="title"
            >
              {{ $t('attachServiceSetting.dialog.services.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <UnitServicesSelect
            testName="formData_services"
            :model.sync="formData.services"
            multiple
            :showAll="true"
          />
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton
          testName="dialog-footer-cancel-btn"
          plain
          @click="showDialog=false, resetForm()"
        >
          {{ $t('common.button.cancel.text')}}
        </BaseElButton>
        <BaseElButton testName="dialog-footer-confirm-btn" type="primary" @click="dialogConfirm">
          {{ dialogType === 'create' ? $t('common.button.create.text') : $t('common.button.save.text') }}
        </BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      testName="delete-dialog-attachService"
      :title="$t('common.dialog.delete.title')"
      :content="$t('common.dialog.delete.content')"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteAttachService(), deleteDialog = false"
    />
  </div>
</template>

<script>
import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { CreateAttachService, GetAttachService, GetAttachServiceCount, DeleteAttachService, UpdateAttachService, FindAttachService } from '@/api/attachService'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { extractList } from '@/utils/helper'
import { dialogTitle } from '@/utils/dialog'
import { mapGetters } from 'vuex'
import { get } from 'lodash'
import { useReservation } from '@/use/useReservation'
import { onMounted, computed } from 'vue'
import { usePermissions } from '@/use/permissions'
import { i18n } from '@/plugins/i18n/i18n'
import store from '@/store'
import { useCurrency } from '@/use/useCurrency'

export default {
  name: 'AttachServiceSetting',
  components: { DeleteDialog, UnitServicesSelect, EmptyBlock, FormItemTooltipLabel },
  setup () {
    const { configData, getConfig } = useReservation()
    const { checkAction } = usePermissions()
    const { displayCurrency, displayCurrencySymbol } = useCurrency()
    const chargeType = computed(() => get(configData, 'reservation.chargeType'))
    const useFeatures = computed(() => {
      return {
        forceCanHidePrice: checkAction('admin.appointmentServiceAttach.enableShowPrice'),
      }
    })
    const usePubApt = computed(() => checkAction('admin.pubAptConfig.page'))
    const isOverseasShop = computed(() => get(store.state, 'org.isOverseasShop'))
    onMounted(async () => {
      getConfig('reservation')
    })
    return {
      chargeType,
      configData,
      useFeatures,
      usePubApt,
      isOverseasShop,
      displayCurrency,
      displayCurrencySymbol,
    }
  },
  computed: {
    ...mapGetters([
      'shop',
    ]),
    dialogTitle () {
      return dialogTitle(
        this.dialogType,
        {
          create: i18n.t('attachServiceSetting.dialog.create.title'),
          update: i18n.t('attachServiceSetting.dialog.edit.title'),
        },
      )
    },
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    shopList () {
      return this.$store.state.shopList
    },

    categoryList () {
      const list = []
      this.formData.serviceCategorys.forEach(item => {
        list.push(item.id)
      })
      return list
    },

    serviceUnitList () {
      const list = []
      this.formData.serviceUnits.forEach(item => {
        list.push(item.id)
      })
      return list
    },

  },
  data: () => ({
    nameSearch: '',
    loading: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',

    selectRow: null,
    selectService: null,

    serviceList: [],
    serviceCount: 0,

    formData: {
      name: '',
      isPublic: true,
      isPubApt: false,
      price: '',
      hideTime: false,
      hidePrice: false,
      bookingTime: '',
      showPrice: '',
      showTime: '',
      order: 100,
      services: [],
    },

    formRules: {
      name: noEmptyRules(),
      price: [noEmptyRules(), isDigitRules(), rangeRules()],
      showTime: [isDigitRules(), rangeRules()],
      // showPrice: noEmptyRules(),
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
    },

    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
  }),

  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },
    async refresh (search = false) {
      if (search) this.tableOptions.page = 1
      this.loading = true
      await this.getAttachService()
      await this.getAttachServiceCount()
      this.loading = false
    },

    async dialogConfirm () {
      if (!await this.checkForm()) return
      const type = this.dialogType
      if (type === 'create') this.createAttachService()
      if (type === 'update') this.updateAttachService()
      this.showDialog = false
    },

    async onCopyService (row) {
      this.selectRow = row
      await this.findAttachService()
      this.syncFormData(row)
      this.openDialog('create')
    },

    syncFormData (row) {
      this.formData.name = row.name
      this.formData.description = row.description
      this.formData.price = row.price

      if (this.chargeType === 'checkout' && !this.useFeatures.forceCanHidePrice) {
        this.formData.hidePrice = false
        this.formData.showPrice = row.showPrice
      } else {
        if (row.showPrice && row.showPrice !== '0') {
          this.formData.hidePrice = true
          this.formData.showPrice = row.showPrice
        }
      }

      if (row.showTime && row.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = row.showTime
      }
      this.formData.bookingTime = row.bookingTime
      this.formData.order = row.order
      this.formData.isPublic = row.isPublic
      this.formData.isPubApt = row.isPubApt
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        name: '',
        isPublic: true,
        isPubApt: false,
        price: '',
        hideTime: false,
        hidePrice: false,
        showPrice: '',
        bookingTime: '',
        showTime: '',
        order: 100,
        services: [],
      }
    },

    //= > 創建服務
    async createAttachService () {
      try {
        const form = this.formData
        if (!form.hidePrice) form.showPrice = '0'
        if (!form.hideTime) form.showTime = '0'
        await CreateAttachService({
          shopId: this.shop,
          name: form.name,
          isPublic: form.isPublic,
          isPubApt: form.isPubApt,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          appointmentServices: extractList('id', form.services),
        })
        await this.refresh()
        this.$message.success(i18n.t('common.message.createSuccess2'))
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 刪除服務
    async deleteAttachService () {
      try {
        await DeleteAttachService({
          shopId: this.selectRow.ShopId,
          id: this.selectRow.id,
        })

        await this.refresh()
        this.$message.success(i18n.t('common.message.deleteSuccess'))
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 更新服務
    async updateAttachService () {
      try {
        const form = this.formData
        if (!form.hidePrice) form.showPrice = '0'
        if (!form.hideTime) form.showTime = '0'
        await UpdateAttachService({
          shopId: this.shop,
          id: this.selectRow.id,
          name: form.name,
          isPublic: form.isPublic,
          isPubApt: form.isPubApt,
          price: form.price,
          showPrice: (form.showPrice === '') ? '0' : form.showPrice,
          showTime: (form.showTime === '') ? 0 : form.showTime,
          bookingTime: form.bookingTime,
          order: form.order,
          appointmentServices: extractList('id', form.services),
        })

        await this.refresh()
        this.$message.success(i18n.t('common.message.updateSuccess'))
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得服務
    async getAttachService () {
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        const res = await GetAttachService({
          shopId: this.shop,
          start: startIndex,
          limit,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceList = res
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    async findAttachService () {
      try {
        const res = await FindAttachService({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.formData.services = res.AppointmentServices.filter(item => !item.isRemove)
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    //= > 取得服務總數
    async getAttachServiceCount () {
      try {
        const res = await GetAttachServiceCount({
          shopId: this.shop,
          name: (this.nameSearch === '') ? undefined : this.nameSearch,
        })
        this.serviceCount = res.data.count
      } catch (error) {
        console.log(error)
        this.$message.warning(error)
      }
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style scoped lang="scss">
</style>
